@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hides list item markers */
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Hides summary markers in mobile Safari */
@media screen and (max-width: 768px) {
  summary::-webkit-details-marker {
    display: none;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: transparent;
}

::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.report-table::-webkit-scrollbar-track {
  /* margin-top: 70px; */
}

body,
html {
  position: fixed;
}

body {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.slick-list {
  padding: 0 !important;
}

.slick-dots li {
  margin: 0 5px; /* Space between dots */
  width: 10px !important; /* Dot width */
  height: 10px !important; /* Dot height */
}

.slick-dots button {
  border-radius: 50%;
  padding: 0 !important;
  width: 10px !important; /* Dot width */
  height: 10px !important; /* Dot height */
  background: #007bff; /* Active dot color */

  background: var(--color-neutral-900, rgba(144, 139, 139, 1)) !important;
}

.slick-dots button::before {
  width: 10px !important; /* Dot width */
  height: 10px !important; /* Dot height */
  display: hidden !important;
  content: "" !important;
}

.slick-dots button:hover {
  background: #888; /* Color on hover */
}

.slick-dots .slick-active button {
  background: var(--color-base, #fff) !important; /* Active dot color */
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}
