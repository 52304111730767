.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrDateDisplayWrapper {
  display: none !important;
}

.rdrNextPrevButton,
.rdrPprevButton {
  display: none;
}

.rdrStartEdge,
.rdrEndEdge {
  background-color: var(--color-custom-darkTeal);
}

.rdrInRange {
  background-color: #e9e8fe;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: white !important;
}

.rdrDayNumber span {
  color: black !important;
}

.rdrMonthAndYearPickers {
  display: none;
}

.rdrMonthAndYearWrapper {
  display: none;
}

.rdrDayPassive {
  opacity: 0;
}

.rdrMonthPicker select {
  padding-left: 0;
}

.rdrMonth {
  padding-left: 0;
  padding-right: 0;
}

.rdrInfiniteMonths {
  height: 320px !important;
}
