.tableau-filter-wrapper {
  /* Existing styles */
  .controls {
    top: 64px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  #toggleFilters {
    padding: 10px 20px;
    cursor: pointer;
  }

  #filterMenu {
    display: none;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .row {
    display: flex;
    gap: 10px;
  }

  button,
  #datePicker {
    /* padding: 10px; */
    text-align: center;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
  }

  .viz-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
  }

  #tableauViz {
    width: 100%;
    height: 100%;
  }

  div#partnersFilter > div {
    min-width: 150px;
    column-gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div#partnersFilter {
    flex-wrap: wrap;
    padding: 10px;
    display: none;
    background-color: #ffffff;
  }

  div#partnersFilter > div > * {
    margin: 0;
    padding: 0;
  }

  /* Newly integrated styles */
  #filter-wrapper {
    transition: 0.3s all;
    transform: translateX(100vw);
  }

  #filter-wrapper.active {
    transform: translateX(0vw);
  }

  #filter-wrapper h4 {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
    color: white;
  }

  .date {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    display: flex;
    gap: 15px;
    width: 100%;
    position: relative;
  }

  .date em {
    font-style: normal;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .form-input select:not(#seasons-weeks) {
    width: 100%;
    padding: 12px 15px;
    background: transparent;
    border-radius: 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    font-size: 15px;
    border: 0px;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .inputs-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }

  .recent-input label {
    padding: 8px 18px;
    border-radius: 30px;
    font-size: 15px;
    cursor: pointer;
    display: block;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    line-height: normal;
  }

  .recent-input input {
    position: absolute;
    opacity: 0;
  }

  .recent-input .input {
    position: relative;
    cursor: pointer;
    width: max-content;
  }

  .recent-input input:checked + label {
    background: #000;
    color: #fff;
    border-color: #000;
  }

  #seasons-weeks {
    width: 100%;
    background: transparent;
    border: 1px solid #9999;
    padding: 10px;
    border-radius: 10px;
  }

  .season {
    padding: 4px 17px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 45%;
    margin: 5px 0 10px 0;
    border: 0px;
    font-family: "Poppins", sans-serif;
    background: transparent;
  }

  .filter-header h4 {
    background: linear-gradient(
      209deg,
      rgba(236, 95, 36, 1) 9%,
      rgba(241, 147, 19, 1) 80%
    );
    padding: 6px 20px;
    line-height: normal;
    color: #fff;
    margin-bottom: 0 !important;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .filter-header h4 svg {
    width: 26px;
    height: 26px;
    filter: invert(90);
  }

  .input-label {
    color: white;
    margin: 0;
  }

  .select2-container {
    display: block;
    width: 100%;
    right: 0px !important;
  }

  #season-type {
    overflow: -moz-hidden-unscrollable;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
    background-position: center right 20px !important;
  }

  #season-type {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .form-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  #datePicker {
    border: 1px solid #99999929 !important;
    border-radius: 10px;
    width: 100%;
  }

  @media (min-width: 775px) {
    #filter-wrapper {
      width: 45%;
    }

    .flatpickr-calendar {
      width: 45% !important;
    }
  }

  @media (max-width: 767px) {
    .form-input button {
      min-width: unset;
      flex: unset;
    }

    .recent-input .inputs-group .input {
      width: calc(100% / 2 - 5px);
    }

    .recent-input .inputs-group .seasson {
      width: 100%;
      display: block;
      margin: 0px;
    }

    .recent-input .inputs-group {
      width: 100%;
      gap: 10px;
    }

    #filter-wrapper.active {
      transform: translateX(0vw);
    }

    .select2-container {
      display: block;
      width: 100%;
      right: unset;
      left: 0 !important;
    }
  }

  .dropdown {
    border-radius: 30px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
    width: 100%;
    margin-bottom: 10px;
    border: 0px;
    margin-right: 5px;
    font-family: "Poppins", sans-serif;
    background: transparent;
  }

  .dropdown .dropdown-list {
    transform: scale(1, 0);
    transition: transform 0.15s ease-in-out 0.15s;
    overflow-y: scroll;
    z-index: 9;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
  }

  .dropdown .dropdown-option {
    display: block;
    padding: 8px 12px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  .dropdown .dropdown-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 6px 12px;
    line-height: 1;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
  }

  .dropdown-label {
    border-radius: 5px;
  }

  .dropdown.on .dropdown-list {
    transform: scale(1, 1);
    transition-delay: 0s;
  }

  .dropdown.on .dropdown-list .dropdown-option {
    opacity: 1;
    transition-delay: 0.2s;
  }

  .dropdown .search-box {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #404040;
    width: 100%;
    color: white !important;
    position: sticky;
    top: 60px;
    z-index: 99;
  }

  .filter-option {
    background-color: #404c52;
  }

  .active-nav-button {
    background-color: #a99456;
  }

  #filter-section-wrapper,
  .addl-filters {
    margin-top: 4%;
    background: #151e1d;
    border-radius: 5px;
    color: white;
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: scroll;
  }

  .main-wrapper {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .dropdown-filter-option {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dropdown-filter-option p {
    color: white;
    margin: 0;
  }

  .active-date-button {
    background-color: #a99456;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .cat-header-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #242a2a;
    border-top: 0.5px solid #242a2a;
    padding: 3% 0;
    margin-bottom: 15px;
  }

  #title-header-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
  }

  #title-header {
    color: #ffffff;
    font-size: 2em;
    font-weight: 200 !important;
    margin-left: 30px;
  }

  label {
    margin-bottom: 1px;
  }

  .open-dropdown-label-div {
    position: sticky;
    top: 0;
    background-color: #020202;
    border-bottom: 0.5px solid #242a2a;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 60px;
  }

  .apply-filter {
    background-color: #a99456 !important;
    padding: 5px;
  }

  .clustered-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
  }

  #datePicker.active {
    background-color: #a99456;
  }

  .align-text-left {
    text-align: left;
  }

  .default-font-size {
    font-size: 14px;
    font-weight: normal;
  }
}

.flatpickr-calendar * {
  max-width: none !important;
  max-height: none !important;
}

.dayContainer {
  width: 100%;
}

.flatpickr-days {
  width: 100%;
}

.flatpickr-weekdays {
  width: 100%;
}

.flatpickr-rContainer {
  width: 100%;
}

#dashboard-spacer {
  width: 100%;
}
