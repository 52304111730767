.slide-container {
  overflow-y: hidden;
}

.custom-round {
  > div:nth-child(2) {
    overflow-x: hidden;
    /* Add any additional styles here */
    height: 100%;
  }
  height: auto !important;
}

.slide-container {
  > div {
    min-height: 0 !important;
  }
}

.gradient-mask {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 2%,
    rgba(255, 255, 255, 1) 98%,
    rgba(255, 255, 255, 0)
  );
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 2%,
    rgba(255, 255, 255, 1) 98%,
    rgba(255, 255, 255, 0)
  );
}
