:root {
  /* B/W colors */
  --color-base: #fff;
  --color-opposite: #000;
  --color-overlay: #00000066;
  --color-primary-text: #fff;

  /* Base colors */
  --color-base-highlight: #fafafa;
  --color-opposite-highlight: #0b0a0a;

  /* Custom colors */
  --color-custom-dropdown-bg: #fafafa;
  --color-custom-dropdown-text: #0a0a0b;
  --color-custom-darkTeal: #2c3333;
  --color-custom-deepCharcoal: #151c1c;
  --color-custom-deepCharcoal10: #151c1c1a;
  --color-custom-buttonText: #3e5564;
  --color-custom-white-10: #ffffff10;
  --color-custom-white-30: #ffffff30;
  --color-custom-white-80: #ffffff80;
  --color-custom-white-90: #ffffff9c;
  --color-custom-white-b3: #ffffffb3;

  /* Grey colors */
  --color-custom-grey-d9: #d9d9d9;
  --color-custom-grey-57: #575757;
  --color-custom-grey-dd: #dddddd;
  --color-custom-grey-fa: #fafafa;
  --color-custom-grey-a3: #a3a3a3;

  /* Primary colors */
  --color-primary-100: #f3f0e7;
  --color-primary-200: #e7e1ce;
  --color-primary-300: #dad2b6;
  --color-primary-400: #cec39e;
  --color-primary-500: #c2b485;
  --color-primary-600: #b6a56d;
  --color-primary-700: #908048;
  --color-primary-800: #76693c;
  --color-primary-900: #5d532f;
  --color-primary-1000: #443c22;
  --color-primary: #a99655;

  /* Secondary colors */
  --color-secondary-100: #ebeff0;
  --color-secondary-200: #d7dee1;
  --color-secondary-300: #c3ced2;
  --color-secondary-400: #afbec2;
  --color-secondary-500: #9badb3;
  --color-secondary-600: #718b93;
  --color-secondary-700: #5f767e;
  --color-secondary-800: #4e6167;
  --color-secondary-900: #3e4c51;
  --color-secondary-1000: #2d383b;
  --color-secondary: #fafafa;

  /* Neutral colors */
  --color-neutral-100: #b8b8b8;
  --color-neutral-200: #cfcece;
  --color-neutral-300: #bab8b8;
  --color-neutral-400: #a5a1a1;
  --color-neutral-500: #908b8b;
  --color-neutral-600: #7a7474;
  --color-neutral-700: #655f5f;
  --color-neutral-800: #4e4949;
  --color-neutral-900: #383434;
  --color-neutral-1000: #221f1f;

  /* Background gradients */
  --bg-primary-gradient: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-item-dark-gradient: linear-gradient(
    15deg,
    var(--color-neutral-1000) 10.41%,
    var(--color-neutral-800) 97.99%
  );

  --bg-chart-gradient1: linear-gradient(
    90deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-chart-gradient1-0deg: linear-gradient(
    1deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2-0deg: linear-gradient(
    1deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-profile-gradient: linear-gradient(
      15deg,
      var(--color-neutral-1000) 10%,
      var(--color-neutral-800) 98%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

[data-theme="sunset"] {
  /* B/W colors */
  --color-base: #fff;
  --color-opposite: #1a535c;
  --color-overlay: #1a535c66;
  --color-primary-text: #fff;

  /* Base colors */
  --color-base-highlight: #fafafa;
  --color-opposite-highlight: #0b0a0a;

  /* Custom colors */
  --color-custom-dropdown-bg: #14444c;
  --color-custom-dropdown-text: #ffffff;

  --color-custom-darkTeal: #438a95;
  --color-custom-deepCharcoal: #2c707b;
  --color-custom-deepCharcoal10: #2c707b1a;
  --color-custom-buttonText: #3e5564;
  --color-custom-white-10: #ffffff10;
  --color-custom-white-30: #ffffff30;
  --color-custom-white-80: #ffffff80;
  --color-custom-white-90: #ffffff9c;
  --color-custom-white-b3: #ffffffb3;

  /* Grey colors */
  --color-custom-grey-d9: #d9d9d9;
  --color-custom-grey-57: #575757;
  --color-custom-grey-dd: #dddddd;
  --color-custom-grey-fa: #fafafa;
  --color-custom-grey-a3: #a3a3a3;

  /* Primary colors */
  --color-primary-100: #ffeded;
  --color-primary-200: #ffc9c9;
  --color-primary-300: #ffa6a6;
  --color-primary-400: #ff8282;
  --color-primary-500: #ff6b6b;
  --color-primary-600: #e65555;
  --color-primary-700: #cc3e3e;
  --color-primary-800: #b32828;
  --color-primary-900: #991111;
  --color-primary-1000: #7f0000;
  --color-primary: #ff6b6b;

  /* Secondary colors */
  --color-secondary-100: #ebeff0;
  --color-secondary-200: #d7dee1;
  --color-secondary-300: #c3ced2;
  --color-secondary-400: #afbec2;
  --color-secondary-500: #9badb3;
  --color-secondary-600: #718b93;
  --color-secondary-700: #5f767e;
  --color-secondary-800: #4e6167;
  --color-secondary-900: #3e4c51;
  --color-secondary-1000: #2d383b;
  --color-secondary: #fafafa;

  /* Neutral colors */
  --color-neutral-100: #e8f0f1;
  --color-neutral-200: #d1e1e3;
  --color-neutral-300: #b9d2d4;
  --color-neutral-400: #a1c2c5;
  --color-neutral-500: #89b3b6;
  --color-neutral-600: #719fa1;
  --color-neutral-700: #5a8b8d;
  --color-neutral-800: #446e71;
  --color-neutral-900: #2d5154;
  --color-neutral-1000: #1a393b;

  /* Background gradients */
  --bg-primary-gradient: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-item-dark-gradient: linear-gradient(
    15deg,
    var(--color-neutral-1000) 10.41%,
    var(--color-neutral-800) 97.99%
  );

  --bg-chart-gradient1: linear-gradient(
    90deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-chart-gradient1-0deg: linear-gradient(
    1deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2-0deg: linear-gradient(
    1deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );
}

[data-theme="neon"] {
  /* B/W colors */
  --color-base: #fff;
  --color-opposite: #eaeaea;
  --color-overlay: #eaeaea66;
  --color-primary-text: #252a34;

  /* Base colors */
  --color-base-highlight: #fafafa;
  --color-opposite-highlight: #0b0a0a;

  /* Custom colors */
  --color-custom-dropdown-bg: #b0b0b0;
  --color-custom-dropdown-text: #0a0a0b;
  --color-custom-darkTeal: #dbdbdb;
  --color-custom-deepCharcoal: #c9c9c9;
  --color-custom-deepCharcoal10: #c9c9c91a;
  --color-custom-buttonText: #252a34;
  --color-custom-white-10: #00000010;
  --color-custom-white-30: #00000030;
  --color-custom-white-80: #00000080;
  --color-custom-white-90: #0000009c;
  --color-custom-white-b3: #000000b3;

  /* Grey colors */
  --color-custom-grey-d9: #d9d9d9;
  --color-custom-grey-57: #575757;
  --color-custom-grey-dd: #dddddd;
  --color-custom-grey-fa: #fafafa;
  --color-custom-grey-a3: #a3a3a3;

  /* Primary colors */
  --color-primary-100: #ffe5eb;
  --color-primary-200: #ffb8cb;
  --color-primary-300: #ff8baa;
  --color-primary-400: #ff5d89;
  --color-primary-500: #ff2e63;
  --color-primary-600: #e62456;
  --color-primary-700: #cc1a49;
  --color-primary-800: #b3103b;
  --color-primary-900: #99082f;
  --color-primary-1000: #7f0023;
  --color-primary: #ff2e63;

  /* Secondary colors */
  --color-secondary-100: #e0fbfa;
  --color-secondary-200: #b3f3f1;
  --color-secondary-300: #85ebe8;
  --color-secondary-400: #58e3df;
  --color-secondary-500: #08d9d6;
  --color-secondary-600: #07c3c1;
  --color-secondary-700: #06aca9;
  --color-secondary-800: #049593;
  --color-secondary-900: #037e7c;
  --color-secondary-1000: #026765;
  --color-secondary: #08d9d6;

  /* Neutral colors */
  --color-neutral-100: #ffffff;
  --color-neutral-200: #f5f5f5;
  --color-neutral-300: #eeeeee;
  --color-neutral-400: #eaeaea;
  --color-neutral-500: #d4d4d4;
  --color-neutral-600: #bebebe;
  --color-neutral-700: #a8a8a8;
  --color-neutral-800: #929292;
  --color-neutral-900: #7c7c7c;
  --color-neutral-1000: #666666;

  /* Background gradients */
  --bg-primary-gradient: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-item-dark-gradient: linear-gradient(
    15deg,
    var(--color-neutral-1000) 10.41%,
    var(--color-neutral-800) 97.99%
  );

  --bg-chart-gradient1: linear-gradient(
    90deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-chart-gradient1-0deg: linear-gradient(
    1deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2-0deg: linear-gradient(
    1deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );
}

[data-theme="light"] {
  /* B/W colors */
  --color-base: #fff;
  --color-opposite: #f3f4f7;
  --color-overlay: #f3f4f766;
  --color-primary-text: #252a34;

  /* Base colors */
  --color-base-highlight: #fafafa;
  --color-opposite-highlight: #0b0a0a;

  /* Custom colors */
  --color-custom-dropdown-bg: #ffffff;
  --color-custom-dropdown-text: #0a0a0b;
  --color-custom-darkTeal: #dbdbdb;
  --color-custom-deepCharcoal: #c9c9c9;
  --color-custom-deepCharcoal10: #c9c9c91a;
  --color-custom-buttonText: #ffffff;
  --color-custom-white-10: #00000010;
  --color-custom-white-30: #00000030;
  --color-custom-white-80: #00000080;
  --color-custom-white-90: #0000009c;
  --color-custom-white-b3: #000000b3;

  /* Grey colors */
  --color-custom-grey-d9: #d9d9d9;
  --color-custom-grey-57: #575757;
  --color-custom-grey-dd: #dddddd;
  --color-custom-grey-fa: #fafafa;
  --color-custom-grey-a3: #a3a3a3;

  /* Primary colors */
  --color-primary-100: #e6edff;
  --color-primary-200: #b3c7ff;
  --color-primary-300: #80a1ff;
  --color-primary-400: #4d7bff;
  --color-primary-500: #3462ff;
  --color-primary-600: #2f58e6;
  --color-primary-700: #284acc;
  --color-primary-800: #223cb3;
  --color-primary-900: #1b2f99;
  --color-primary-1000: #14217f;
  --color-primary: #3462ff;

  /* Secondary colors */
  --color-secondary-100: #e9f8e1;
  --color-secondary-200: #c7eeb0;
  --color-secondary-300: #a5e37f;
  --color-secondary-400: #82d94f;
  --color-secondary-500: #5eb525;
  --color-secondary-600: #549e21;
  --color-secondary-700: #49871c;
  --color-secondary-800: #3f7018;
  --color-secondary-900: #355913;
  --color-secondary-1000: #2a420f;
  --color-secondary: #5eb525;

  /* Neutral colors */
  --color-neutral-100: #ffffff;
  --color-neutral-200: #f9fafc;
  --color-neutral-300: #f3f4f7;
  --color-neutral-400: #e5e7eb;
  --color-neutral-500: #d3d6dc;
  --color-neutral-600: #c2c5cd;
  --color-neutral-700: #adb1b9;
  --color-neutral-800: #9aa0a8;
  --color-neutral-900: #878d96;
  --color-neutral-1000: #747b83;

  /* Background gradients */
  --bg-primary-gradient: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-item-dark-gradient: linear-gradient(
    15deg,
    var(--color-neutral-1000) 10.41%,
    var(--color-neutral-800) 97.99%
  );

  --bg-chart-gradient1: linear-gradient(
    90deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-chart-gradient1-0deg: linear-gradient(
    1deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2-0deg: linear-gradient(
    1deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );
}

[data-theme="earthy"] {
  /* B/W colors */
  --color-base: #fff;
  --color-opposite: #2a2a2a;
  --color-overlay: #2a2a2a66;
  --color-primary-text: #fff;

  /* Base colors */
  --color-base-highlight: #fafafa;
  --color-opposite-highlight: #0b0a0a;

  /* Custom colors */
  --color-custom-dropdown-bg: #ffffff;
  --color-custom-dropdown-text: #0a0a0b;
  --color-custom-darkTeal: #282828;
  --color-custom-deepCharcoal: #202020;
  --color-custom-deepCharcoal10: #2020201a;
  --color-custom-buttonText: #000;
  --color-custom-white-10: #ffffff10;
  --color-custom-white-30: #ffffff30;
  --color-custom-white-80: #ffffff80;
  --color-custom-white-90: #ffffff9c;
  --color-custom-white-b3: #ffffffb3;

  /* Grey colors */
  --color-custom-grey-d9: #d9d9d9;
  --color-custom-grey-57: #575757;
  --color-custom-grey-dd: #aaaaaa;
  --color-custom-grey-fa: #cccccc;
  --color-custom-grey-a3: #a3a3a3;

  /* Primary colors */
  --color-primary-100: #fce5e5;
  --color-primary-200: #f8b3b3;
  --color-primary-300: #f48080;
  --color-primary-400: #f04d4d;
  --color-primary-500: #db0000;
  --color-primary-600: #c20000;
  --color-primary-700: #a90000;
  --color-primary-800: #8f0000;
  --color-primary-900: #760000;
  --color-primary-1000: #5d0000;
  --color-primary: #db0000;

  /* Secondary colors */
  --color-secondary-100: #fde8e8;
  --color-secondary-200: #facfcc;
  --color-secondary-300: #f6b7b0;
  --color-secondary-400: #f39e94;
  --color-secondary-500: #f08678;
  --color-secondary-600: #d7746a;
  --color-secondary-700: #bf625c;
  --color-secondary-800: #a6514e;
  --color-secondary-900: #8d4040;
  --color-secondary-1000: #743032;
  --color-secondary: #faeded;

  /* Neutral colors */
  --color-neutral-100: #f5f5f5;
  --color-neutral-200: #ebebeb;
  --color-neutral-300: #d6d6d6;
  --color-neutral-400: #bfbfbf;
  --color-neutral-500: #a6a6a6;
  --color-neutral-600: #8f8f8f;
  --color-neutral-700: #787878;
  --color-neutral-800: #606060;
  --color-neutral-900: #4a4a4a;
  --color-neutral-1000: #363636;

  /* Background gradients */
  --bg-primary-gradient: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-item-dark-gradient: linear-gradient(
    15deg,
    var(--color-neutral-1000) 10.41%,
    var(--color-neutral-800) 97.99%
  );

  --bg-chart-gradient1: linear-gradient(
    90deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );

  --bg-chart-gradient1-0deg: linear-gradient(
    1deg,
    #f8f8f8 0%,
    var(--color-secondary) 100%
  );

  --bg-chart-gradient2-0deg: linear-gradient(
    1deg,
    var(--color-primary) 0%,
    var(--color-primary-800) 100%
  );
}

[data-theme="custom"] {
  --color-base: #fff;
}
